import React from 'react'
import styled from '@emotion/styled'
import { useTheme } from 'emotion-theming'
import { useLinkEval, mq } from '../../hooks'
import { Link as GatsbyLink } from 'gatsby'
import ModalVideo from '../../components/ModalVideo/ModalVideo'

// /////////////////////////////////////////////////////////////////////////////
// Utility Functions:
// /////////////////////////////////////////////////////////////////////////////

const handleSpacing = (typeObject, spacing) => {
  switch (spacing) {
    case 'comfy':
      return typeObject.comfy
    case 'compact':
      return typeObject.compact
    default:
      return typeObject.standard
  }
}

// /////////////////////////////////////////////////////////////////////////////
// Styled Text Elements:
// /////////////////////////////////////////////////////////////////////////////

const Default = styled.p(props => ({
  ...handleSpacing(props.theme.type.scales.body, props.spacing),
  ...props.textStyle,
}))

const Display = styled.h1(props => ({
  ...handleSpacing(props.theme.type.scales.display, props.spacing),
  ...props.textStyle,
}))

const TitleLarge = styled.h2(props => ({
  ...handleSpacing(props.theme.type.scales.titleLarge, props.spacing),
  ...props.textStyle,
}))

const TitleMedium = styled.h3(props => ({
  ...handleSpacing(props.theme.type.scales.titleMedium, props.spacing),
  ...props.textStyle,
}))

const TitleSmall = styled.h4(props => ({
  ...handleSpacing(props.theme.type.scales.titleSmall, props.spacing),
  ...props.textStyle,
}))

const TitleXs = styled.h5(props => ({
  ...handleSpacing(props.theme.type.scales.titleXs, props.spacing),
  ...props.textStyle,
}))

const TitleXxs = styled.h5(props => ({
  ...handleSpacing(props.theme.type.scales.titleXxs, props.spacing),
  ...props.textStyle,
}))

const Body = styled.p(props => ({
  ...handleSpacing(props.theme.type.scales.body, props.spacing),
  ...props.textStyle,
}))

const BodySmall = styled.p(props => ({
  ...handleSpacing(props.theme.type.scales.bodySmall, props.spacing),
  ...props.textStyle,
}))

const FootNote = styled.p(props => ({
  ...handleSpacing(props.theme.type.scales.footNote, props.spacing),
  ...props.textStyle,
}))

const Caption = styled.p(props => ({
  ...handleSpacing(props.theme.type.scales.caption, props.spacing),
  ...props.textStyle,
}))

const OrderedList = styled.ol(props => ({
  ...props.theme.type.styles.ol,
  ...props.textStyle,
}))

const OrderedListItem = styled.li(props => ({
  ...handleSpacing(props.theme.type.scales.body, props.spacing),
  ...props.theme.type.styles.li,
  ...props.textStyle,
}))

const UnorderedList = styled.ul(props => ({
  ...props.theme.type.styles.ul,
  ...props.textStyle,
}))

const UnorderedListItem = styled.li(props => ({
  ...handleSpacing(props.theme.type.scales.body, props.spacing),
  ...props.theme.type.styles.li,
  ...props.textStyle,
}))

const TextLink = ({ link, textStyle, children }) => {
  const theme = useTheme()
  const linkType = useLinkEval(link)

  const inner = (
    <span
      css={mq({
        ...theme.type.styles.link,
        '&:link': {
          ...theme.type.styles.link,
        },
        '&:hover': { ...theme.type.styles.link },
        '&:active': { ...theme.type.styles.link },
        '&:visited': { ...theme.type.styles.link },
      })}
    >
      {children}
    </span>
  )
  // const keepInline = textStyle.keepInline ? true : false

  const conditionalInner = () => {
    if (linkType == 'gatsby') {
      return <GatsbyLink to={link}>{inner}</GatsbyLink>
    } else if (linkType == 'video') {
      return (
        <ModalVideo link={link} textStyle={textStyle}>
          {inner}
        </ModalVideo>
      )
    } else {
      return <a target='_blank' href={link}>{inner}</a>
    }
  }

  return <span css={{ ...textStyle }}>{conditionalInner()}</span>
}

const ButtonLink = ({ link, textStyle, children, newTab }) => {
  const theme = useTheme()
  const linkType = useLinkEval(link)
  const openNewTab = !!newTab
  const inner = (
    <div
      css={mq({
        ...theme.type.styles.button,
        ...theme.shapes.buttons,
        '&:hover': {
          borderColor: '#1079BC',
          backgroundColor: 'white',
          color: '#1079BC',
        },

        borderColor: '#1079BC',
        backgroundColor: '#1079BC',
        color: 'white',
      })}
    >
      {children}
    </div>
  )

  const conditionalInner = openNewTab => {
    if (linkType == 'gatsby') {
      return <GatsbyLink to={`${link}/`}>{inner}</GatsbyLink>
    } else if (linkType == 'video') {
      return <ModalVideo link={link}>{inner}</ModalVideo>
    } else {
      return openNewTab ? (
        <a target="_blank" rel="noopener noreferrer" href={`${link}`}>
          {inner}
        </a>
      ) : (
          <a href={link}>{inner}</a>
        )
    }
  }

  return (
    <div css={mq({ display: 'flex', margin: '2rem 0', ...textStyle })}>
      {conditionalInner(openNewTab)}
    </div>
  )
}

const ButtonTransparent = ({ link, textStyle, children, newTab }) => {
  const theme = useTheme()
  const linkType = useLinkEval(link)
  const openNewTab = newTab ? true : false
  const inner = (
    <div
      css={mq({
        ...theme.type.styles.button,
        ...theme.shapes.buttons,
        ...theme.backgrounds.button.tertiary,
        '&:hover': {
          borderColor: ['white', 'white', 'white', 'white'],
          backgroundColor: ['transparent', 'transparent', 'white', 'white'],
          color: ['#1079BC', '#1079BC', '#1079BC', '#1079BC'],
        },

        borderColor: ['white', 'white', 'white', 'white'],
        backgroundColor: ['#1079BC', '#1079BC', 'transparent', 'transparent'],
        color: ['white', 'white', 'white', 'white'],
      })}
    >
      {children}
    </div>
  )

  const conditionalInner = openNewTab => {
    if (linkType == 'gatsby') {
      return <GatsbyLink to={link}>{inner}</GatsbyLink>
    } else if (linkType == 'video') {
      return <ModalVideo link={link}>{inner}</ModalVideo>
    } else {
      return openNewTab ? (
        <a target="_blank" rel="noopener noreferrer" href={link}>
          {inner}
        </a>
      ) : (
          <a href={link}>{inner}</a>
        )
    }
  }

  return (
    <div css={mq({ display: 'flex', margin: '2rem 0', ...textStyle })}>
      {conditionalInner(openNewTab)}
    </div>
  )
}

const Quote = styled.p(props => ({
  ...handleSpacing(props.theme.type.scales.quote, props.spacing),
  ...props.textStyle,
}))

const Attribution = styled.p(props => ({
  ...handleSpacing(props.theme.type.scales.attribution, props.spacing),
  ...props.textStyle,
}))

const BlueText = styled.span({ color: '#003da6' })
const Bold = styled.span({ fontWeight: '600' })
const Italic = styled.span({ fontStyle: 'italic' })
const Underline = styled.span({ textDecoration: 'underline' })
const SuperScript = styled.sup(props => ({
  verticalAlign: 'super',
  fontSize: '0.6rem',
  ...props.textStyle,
}))

export {
  Display,
  TitleLarge,
  TitleMedium,
  TitleSmall,
  TitleXs,
  TitleXxs,
  Body,
  BodySmall,
  FootNote,
  Caption,
  TextLink,
  ButtonLink,
  ButtonTransparent,
  OrderedList,
  OrderedListItem,
  UnorderedList,
  UnorderedListItem,
  Bold,
  Italic,
  Underline,
  SuperScript,
  Quote,
  Attribution,
  BlueText,
}
